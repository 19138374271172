import TextBase from './TextBase.js';

const defaultStyles = {
    paddingTop: '20px',
    lineHeight: 1.6
}

const defaultColor = 'primary.main';

export default function Paragraph (props) {
    const { styles, color, position, fontSize, fontWeight, children } = props;

    return (
        <TextBase 
            styles={{ ...defaultStyles, ...styles }}
            fontSize={fontSize}
            fontWeight={fontWeight}
            color={color || defaultColor} 
            position={position} 
            variant='body1'>
            {children}
        </TextBase>
    )
}