import TextBase from './TextBase.js';

const defaultPosition = 'centered';
const defaultColor = 'primary.main';

export default function Header4 (props) {
    const { styles, color, position, children } = props;

    return (
        <TextBase 
            styles={styles} 
            color={color || defaultColor} 
            position={position || defaultPosition} 
            variant='h4'>
            {children}
        </TextBase>
    )
}