import TextBase from './TextBase.js';

const defaultStyles = {
    paddingTop: '6.5vh'
}

const defaultColor = 'primary.main';
const defaultPosition = 'centered';

export default function Header3 (props) {
    const { styles, color, position, children } = props;

    return (
        <TextBase 
            styles={{ ...defaultStyles, ...styles }} 
            color={color || defaultColor} 
            position={position || defaultPosition} 
            variant='h3'>
            {children}
        </TextBase>
    )
}