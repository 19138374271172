import { mediaQueryMaxWidth } from './breakpoints';

const mdBreakpointMax = mediaQueryMaxWidth('md');
const smBreakpointMax = mediaQueryMaxWidth('sm');

export default {
  fontFamily: [
    'Fira Sans'
  ].join(','),
  h1: {
    fontSize: '5vw',
    fontWeight: 700,
    [mdBreakpointMax]: {
      fontSize: '2.25rem'
    }
  },
  h2: {
    fontSize: '3vw',
    fontWeight: 700,
    [mdBreakpointMax]: {
      fontSize: '1.75rem'
    }
  },
  h3: {
    fontSize: '2.75rem',
    fontWeight: 700,
    [mdBreakpointMax]: {
      fontSize: '2rem'
    },
    [smBreakpointMax]: {
      fontSize: '1.4rem'
    }
  },
  h4: {
    fontSize: '2.25rem',
    fontWeight: 700,
    [mdBreakpointMax]: {
      fontSize: '1.5rem'
    }
  },
  body1: {
    fontSize: '1.25rem',
    fontWeight: 400,
    [mdBreakpointMax]: {
      fontSize: '0.95rem'
    }
  },
  button: {
    fontSize: '1.25rem',
    fontWeight: 700
  }
}