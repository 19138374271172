import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

import RecentWork from './sections/RecentWork';
import TopBar from './sections/TopBar';
import Hero from './sections/Hero';
import Pitch from './sections/Pitch';
import FeeSchedule from './sections/FeeSchedule';
import SectionDivider from './common/SectionDivider';
import Testimonials from './sections/Testimonials';

function App() {
  return (
    <div>
      <Container 
        maxWidth={false}
        sx={{  backgroundColor: 'primary.main', margin: 0, width: '100vw' }}>
        <Grid
          container
          flexDirection='column'
          sx={{ maxHeight: '70vh' }}>
          <TopBar />

          <Hero />
        </Grid>
      </Container>

      <Pitch />

      <RecentWork />

      <Testimonials />

      <SectionDivider />

      <FeeSchedule />
    </div>
  );
}

export default App;
