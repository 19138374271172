import Box from '@mui/material/Box';

export default function SectionDivider () {
    return (
        <Box
            sx={{
                marginTop: '6.5vh',
                borderTopColor: 'neutral.main', 
                borderTopStyle: 'solid', 
                borderTopWidth: '5px' 
            }}
        />
    )
}