import React from 'react';

import ButtonBase from './ButtonBase';

export default function ButtonLink (props) {
    const { 
        styles, 
        href, 
        fillColor,
        textColor,
        startIcon, 
        children
    } = props;

    return (
        <ButtonBase
            styles={styles}
            href={href}
            fillColor={fillColor}
            textColor={textColor}
            startIcon={startIcon}>
            {children}
        </ButtonBase>
    );
}