import SectionContainer from '../common/SectionContainer';
import Header3 from '../common/text/Header3';
import Paragraph from '../common/text/Paragraph';

import testimonialsCopy from '../copy/testimonials.json';

export default function Testimonials (props) {
    return (
        <SectionContainer>
            <Header3 position='centered'>{testimonialsCopy.title}</Header3>

            {
                testimonialsCopy.testimonials.map(testimonial => (
                    <>
                        <Paragraph position='centered'>"{testimonial.text}"</Paragraph>
                        <Paragraph styles={{ paddingTop: '10px' }} position='centered' fontWeight='bold'>{testimonial.author}</Paragraph>
                    </>
                ))
            }
        </SectionContainer>
    )
}