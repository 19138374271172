import MuiButton from '@mui/material/Button';

const defaultFillColor = 'primary.main';
const defaultTextColor = 'primary.contrastText';

export default function ButtonBase (props) {
    const { 
        styles,
        fillColor,
        textColor, 
        startIcon, 
        href,
        children
    } = props;

    return (
        <MuiButton
            variant='contained' 
            sx={{ paddingTop: '6px', ...styles }}
            borderRadius='10px'
            href={href}
            disabledElevation
            color={fillColor || defaultFillColor}
            textColor={textColor || defaultTextColor}
            startIcon={startIcon}>
            {children}
        </MuiButton>
    )
}