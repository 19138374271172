import CircleCheckIcon from "./CircleCheckIcon";
import CircleQuestionIcon from "./CircleQuestionIcon";
import EmailIcon from "./EmailIcon";
import HouseIcon from "./HouseIcon";
import TreeBranchIcon from "./TreeBranchIcon";
import TrophyIcon from "./TrophyIcon";

const iconOptions = {
    'tree': TreeBranchIcon,
    'check': CircleCheckIcon,
    'question': CircleQuestionIcon,
    'trophy': TrophyIcon,
    'email': EmailIcon,
    'house': HouseIcon
}

export default function Icon (props) {
    const { name, color, size } = props;
    const SelectedIcon = iconOptions[name];

    return <SelectedIcon color={color} fontSize={size} />
}