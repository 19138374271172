
const breakpoints = {
    xs: 0,
    sm: 600,
    md: 840,
    lg: 1100,
    xl: 1300
}

const unit = 'px';

export function mediaQueryMaxWidth (size) {
    return `@media (max-width: ${breakpoints[size]}${unit})`;
}

export function mediaQueryMinWidth (size) {
    return `@media (min-width: ${breakpoints[size]}${unit})`;
}

export default {
    values: {
        xs: breakpoints.xs,
        sm: breakpoints.sm,
        md: breakpoints.md,
        lg: breakpoints.lg,
        xl: breakpoints.xl
    }
}