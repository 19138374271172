export default {
  primary: {
    main: '#001f3d',
    // main: '#00274e', // a little lighter
    contrastText: 'white'
  },
  buttonText: {
    main: 'white'
  },
  secondary: {
    main: '#F0A000'
  },
  red: {
    main: '#ca0b44'
  },
  green: {
    main: '#00654c'
  },
  neutral: {
    main: '#f1f2f3',
    contrast: '#e1e6ea'
  },
  action: {
    main: '#ca0b44',
    dark: '#b30034'
  }
}