import TextBase from './TextBase.js';

const defaultStyles = {
    paddingTop: '7vh'
}

const defaultColor = 'primary.main';
const defaultPosition = 'centered';

export default function Header1 (props) {
    const { styles, color, position, children } = props;

    return (
        <TextBase 
            styles={{ ...defaultStyles, ...styles }} 
            color={color || defaultColor}
            position={position || defaultPosition} 
            variant='h1'>
            {children}
        </TextBase>
    )
}