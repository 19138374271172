import Header3 from '../common/text/Header3';
import Paragraph from '../common/text/Paragraph';
import SectionContainer from '../common/SectionContainer';
import List from '../common/List';
import ListItem from '../common/ListItem';

import pitchCopy from '../copy/pitch.json';

export default function Pitch () {
    return (
        <SectionContainer>
            {
                pitchCopy.sections.map(section => (
                    <>
                        <Header3 position='centered'>{section.title}</Header3>

                        {
                            section.paragraphs.map(text => (
                                <Paragraph>{text}</Paragraph>
                            ))
                        }

                        <List styles={{ paddingBottom: 0 }}>
                            {
                                section.bullets.map((text, idx) => (
                                    <ListItem styles={ idx === section.bullets.length ? { paddingBottom: 0 } : {}} iconName={section.bulletIconName} iconColor={section.bulletIconColor} iconSize='medium'>{text}</ListItem>
                                ))
                            }
                        </List>
                    </>
                ))
            }
        </SectionContainer>
    )
}