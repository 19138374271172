import Box from '@mui/material/Box';

import Header1 from '../common/text/Header1';
import Header2 from '../common/text/Header2';

import { mediaQueryMinWidth } from '../theme/breakpoints';

import heroCopy from '../copy/hero.json';

export default function Hero () {
    return (
        <Box
            backgroundColor='primary.main'
            display='flex'
            flexDirection='column'
            justifyContent='center'
            flexGrow={1}
            sx={{
              paddingBottom: '12vh',
              [mediaQueryMinWidth('sm')]: {
                paddingLeft: '50px',
                paddingRight: '50px'
              },
              [mediaQueryMinWidth('md')]: {
                paddingLeft: '100px',
                paddingRight: '100px'
              },
              [mediaQueryMinWidth('lg')]: {
                paddingLeft: '150px',
                paddingRight: '150px'
              },
              [mediaQueryMinWidth('xl')]: {
                paddingLeft: '250px',
                paddingRight: '250px'
              }
            }}>
            <Header1 color='secondary.main' position='left'>{heroCopy.title}</Header1>

            <Header2 color='primary.contrastText' position='left'>{heroCopy.subtitle}</Header2>
        </Box>
    )
}