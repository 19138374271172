import Container from '@mui/material/Container';

import { mediaQueryMaxWidth } from '../theme/breakpoints';

const defaultStyles = {
    padding: 0,
    width: '840px',
    [mediaQueryMaxWidth('md')]: {
        width: '100%',
        padding: '0 16px'
    }
}

export default function SectionContainer (props) {
    const { styles, id, children } = props;

    return (
        <Container
            id={id}
            sx={{
                ...defaultStyles,
                ...styles
             }}>
            {children}
        </Container>
    )
}