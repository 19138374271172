import MuiLink from '@mui/material/Link';

const defaultUnderline = 'always';

export default function LinkBase (props) {
    const { href, newTab = false, underline, children } = props;

    return (
        <MuiLink
            href={href}
            underline={underline || defaultUnderline}
            target={newTab ? '_blank' : ''}
            rel={newTab ? 'noopener' : ''}>
            {children}
        </MuiLink>
    )
}