import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import Paragraph from '../common/text/Paragraph';
import Image from '../common/Image';
import Header3 from '../common/text/Header3';
import SectionContainer from '../common/SectionContainer';
import LinkBase from '../common/LinkBase';

import recentWorkCopy from '../copy/recent-work.json';

const packageRoute_logo_path = 'PackageRoute_logo.svg';

export default function RecentWork () {
    return (
        <Box
            sx={{
                backgroundColor: 'neutral.main',
                marginTop: '6.5vh',
                paddingBottom: '6.5vh'
            }}>
            <SectionContainer>
                <Header3 centered>{recentWorkCopy.title}</Header3>

                {
                    recentWorkCopy.recentWork.map(recentWork => (
                        <Grid container direction='column'>
                            <Grid container item direction='column' alignItems='center' sx={{ marginTop: '6.5vh' }}>
                                <LinkBase href={recentWork.logoImageHref} newTab>
                                    <Image
                                        styles={{ height: '8vh'}}
                                        src={recentWork.logoImagePath}
                                    />
                                </LinkBase>

                                {
                                    recentWork.paragraphs.map(text => (
                                        <Paragraph>{text}</Paragraph>
                                    ))
                                }
                            </Grid>
                        </Grid>
                    ))
                }
            </SectionContainer>
        </Box>
    )
}