import MuiListItem from '@mui/material/ListItem';
import MuiListItemIcon from '@mui/material/ListItemIcon';
import MuiListItemText from '@mui/material/ListItemText';

import Icon from '../icons/Icon';
import Paragraph from '../common/text/Paragraph';

const defaultStyles = {
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: '8px',
    paddingRight: 0
}

export default function ListItem (props) {
    const { styles, iconName, iconColor, iconSize, children } = props;

    return (
        <MuiListItem sx={{ ...defaultStyles, ...styles }}  alignItems='flex-start'>
            {
                !!iconName &&
                    <MuiListItemIcon sx={{ minWidth: '35px' }}>
                        <Icon name={iconName} color={iconColor} size={iconSize} />
                    </MuiListItemIcon>
            }

            <MuiListItemText>
                <Paragraph styles={{ paddingTop: 0 }}>{children}</Paragraph>
            </MuiListItemText>
        </MuiListItem>
    )
}