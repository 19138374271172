import Grid from '@mui/material/Grid';

import ButtonLink from '../common/ButtonLink'
import Header4 from '../common/text/Header4';
import IconWithText from '../icons/IconWithText';

import { mediaQueryMaxWidth } from '../theme/breakpoints';

export default function TopBar () {
    return (
        <Grid container item sx={{ width: '100%', padding: '2vh 0' }} alignItems='center' justifyContent='space-between'>
            <Grid
                item 
                alignSelf='center' 
                sx={{
                [mediaQueryMaxWidth('md')]: {
                    justifyContent: 'flex-start'
                }
                }}>
                <IconWithText name='tree' color='green' size='large'>
                    <Header4 color='primary.contrastText'>Jamieson Bates</Header4>
                </IconWithText>
            </Grid>

            <Grid item alignSelf='flex-end'>
                <ButtonLink styles={{ color: 'primary.contrastText' }} href={'#hireme'} fillColor='action'>Hire Me!</ButtonLink>
            </Grid>
        </Grid>
    )
}