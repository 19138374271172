import MuiList from '@mui/material/List';

export default function List (props) {
    const { styles, children } = props;

    return (
        <MuiList sx={{ ...styles }} dense>
            {children}
        </MuiList>
    )
}