import Box from '@mui/material/Box';
import Icon from './Icon'

export default function IconWithText (props) {
    const { name, color, size, children } = props;

    return (
        <Box display='flex' flexDirection='row' alignItems='center'>
            <Icon name={name} color={color} size={size} />

            <Box sx={{ marginLeft: '10px' }}>
                {children}
            </Box>
        </Box>
    )
}