import Typography from '@mui/material/Typography';

export default function Text (props) {
    const { styles, variant = 'body1', color, position, fontSize, fontWeight, children } = props;
    
    return (
        <Typography
            sx={{
                ...styles 
            }}
            fontSize={fontSize}
            variant={variant} 
            color={color}
            textAlign={textPosition(position)}
            fontWeight={fontWeight}>
            {children}
        </Typography>
    )
}

function textPosition (option) {
    const defaultTextPosition = 'start';
    const textPositionOptions = {
        'centered': 'center',
        'left': 'start',
        'right': 'end'
    }
    
    const position = textPositionOptions[option] || defaultTextPosition;

    return position;
}